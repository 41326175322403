<template>
  <component :is="layout">
    <div class="row fadeInUp" role="main">
      <div class="col-12 col-md-3">
        <ConfigurationNav />
      </div>
      <div class="col-12 col-md-9">
        <div class="card border-0 shadow-sm">
          <div class="card-header">
            <h4>
              Api keys
              <connect-button
                class="btn btn-sm float-right btn-outline-secondary ml-4"
                :provider="'WooCommerce'"
                :organization="getOrg()"
              />
              <button
                class="btn btn-sm float-right btn-outline-secondary"
                @click="downloadPlugin"
              >
                Download plugin
              </button>
            </h4>
          </div>
          <div v-if="Object.keys(settings).length !== 0" class="card-body">
            <data-table
              :headers="headers()"
              :data="settings"
              :sort-url="'/configurationSetting'"
            >
              <template #order_number="{ item }">
                {{ item.relation }}
              </template>
              <template #condition_count="{ item }">
                {{ item.key }}
              </template>
              <template #date="{ item }">
                {{ item.value }}
              </template>
            </data-table>
          </div>
        </div>
        <IntegrationSettings />
      </div>
    </div>
  </component>
</template>

<script>
import { mapGetters } from "vuex";
import jsonAPI from "@/api";
import Default from "@/layouts/default.vue";
import DataTable from "@/components/DataTable/DataTable.vue";
import IntegrationSettings from "@/components/Account/IntegrationSettings.vue";
import ConfigurationNav from "@/components/Nav/ConfigurationNav.vue";
import ConnectButton from "@/components/Account/ConnectButton.vue";

export default {
  components: {
    Default,
    ConnectButton,
    DataTable,
    IntegrationSettings,
    ConfigurationNav,
  },
  beforeRouteEnter(to, from, next) {
    let uri = "/configurationSetting";
    jsonAPI.get(uri).then((response) => {
      next((vm) => {
        const settings = response.data.data;
        settings.forEach((elem) => {
          vm.settings.push(elem);
        });
      });
    });
  },
  data: function() {
    return {
      layout: "Default",
      loading: false,
      settings: [],
      integrations: [],
      step_status: {
        key: "install_plugin",
        status: true,
      },
    };
  },
  methods: {
    ...mapGetters(["getOrganization"]),
    headers() {
      return [
        {
          title: "Integration",
          key: "relation",
          sortable: false,
          sortkey: "relation",
        },
        { title: "Property", key: "key", sortable: false, sortkey: "key" },
        { title: "Value", key: "value", sortable: false, sortkey: "value" },
      ];
    },
    getOrg() {
      if (this.getOrganization) {
        return this.getOrganization();
      }
    },
    async downloadPlugin() {
      await jsonAPI({
        url: "/download",
        method: "GET",
        responseType: "blob",
      })
        .then((resp) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([resp.data], { type: "application/octet-stream" })
          );
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "automation-app.zip");
          document.body.appendChild(fileLink);
          fileLink.click();

          this.$store.dispatch("CHECK_ONBOARDING_STEP", this.step_status);
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
  },
};
</script>
