<template>
  <div class="card border-0 shadow-sm">
    <div class="card-header">
      <p class="mb-0">
        External connections
        <Tooltip
          :title="
            'There are 4 connections with WooCommerce. Create order, Update order, Create customer, Update customer'
          "
        />
      </p>
    </div>
    <div class="card-body" v-if="integrations.length !== 0">
      <div
        class="mb-2 pb-2 d-flex flex-row gap-3 border-bottom"
        v-for="integration in integrations"
        :key="integration.name"
      >
        <div class="pr-4 d-flex align-items-center">
          <img
            :src="'/' + integration.logo"
            :alt="integration.name"
            style="width: 50px; height: auto;"
          />
        </div>
        <div class="flex-fill">
          <p class="mb-0">
            <router-link :to="'/organization/integrations/' + integration.id">
              {{ integration.name }}
            </router-link>
          </p>
          <small>{{ integration.description }}</small>
        </div>
        <div>
          <Tag
            :name="tag"
            :color="'#666666'"
            v-for="tag in integration.tags"
            :key="tag"
          />
        </div>
      </div>
    </div>
    <div class="card-body" v-else>
      <p class="text-muted">
        This section shows a list of your connected api's.
      </p>
      <div class="p-4 rounded border-zip text-center">
        <p class="mb-0">No connections available!</p>
      </div>
    </div>
  </div>
</template>

<script>
import jsonAPI from "@/api";
import Tooltip from "@/components/UI/Tooltip.vue";
import integrations from "@/helpers/integrations";
import Tag from "../UI/Tag.vue";

export default {
  name: "IntegrationSettings",
  components: {
    Tag,
    Tooltip,
  },
  data() {
    return {
      integrations: integrations,
      settings: [],
    };
  },
  methods: {
    headers() {
      return [
        {
          title: "Provider",
          key: "provider",
          sortable: false,
          sortkey: "provider",
        },
        {
          title: "Consumer key",
          key: "consumer_key",
          sortable: false,
          sortkey: "consumer_key",
        },
        {
          title: "Consumer secret",
          key: "consumer_secret",
          sortable: false,
          sortkey: "consumer_secret",
        },
      ];
    },
  },
  mounted() {
    jsonAPI.get("organization/current").then((response) => {
      if (response.data.settings && response.data.settings.integration) {
        this.settings = response.data.settings.integration;
      }
    });
  },
};
</script>

<style scoped></style>
